<template>
  <el-dialog
    :title="showContents.ggjkpg"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="1000px"
  >
    <el-table
      :data="listData"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      border
      stripe
      style="margin-top: 20px"
    >
      <el-table-column
        prop="fatherNumber"
        :label="showContents.tmh"
        min-width="160"
      >
        <template slot-scope="scope">
          {{ scope.row.fatherNumber }}-{{ scope.row.identifier }}
        </template>
      </el-table-column>

      <!-- <el-table-column prop="identifier" label="识别码" min-width="120"></el-table-column> -->
      <el-table-column
        prop="p1np"
        :label="showContents.p1npValue"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="vd"
        :label="showContents.vdValue"
        min-width="120"
      ></el-table-column>

      <el-table-column :label="showContents.createTime" min-width="160">
        <template slot-scope="scope">
          <p>{{ scope.row.createTime | dateFormat1 }}</p>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        prop="operation"
        :label="showContents.operation"
        width="100px;"
      >
        <template slot-scope="scope">
          <div class="tableOperation">
            <el-button type="text" @click="downloadReport(scope.row.id)">{{
              showContents.xzbg
            }}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 10,
        current: 1,
        sex: "女",
        userLoginId: "",
      },
      visible: false,
      showContents: {
        ggjkpg: "骨骼健康评估",
        tmh: "条码号",
        p1npValue: "p1np值",
        vdValue: "vd值",
        createTime: "创建时间",
        operation: "操作",
        xzbg: "下载报告",
      },
    };
  },
  created() {
    if (this.$store.state.language == "en") {
      this.showContents = {
        ggjkpg: "Skeletal Health Assessment",
        tmh: "barCode",
        p1npValue: "p1npValue",
        vdValue: "vdValue",
        createTime: "createTime",
        operation: "operation",
        xzbg: "Download report",
      };
    }
  },
  methods: {
    downloadReport(id) {
      let url = "";
      if (!location.port) {
        url = "http://192.168.1.54:8119";
        url = "https://api.predicthealth.cn";
      } else url = `https://api.predicthealth.cn`;
      window.open(`${url}/user-data-details/exportPdfById/${id}`);
    },
    init(Numbering) {
      this.getDataList(Numbering);
      this.visible = true;
    },
    /**
     * 加载列表数据数据
     */
    getDataList(Numbering) {
      this.$http
        .get(
          `/user-data-details/getListByNumbering?Numbering=${Numbering}&identifier=1`
        )
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            console.log(data);
            this.listData = data;
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped></style>
